<template>
  <div>
    <sedirector_app_bar />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        :title="
          'ข้อมูลผู้สมัครสอบตำแหน่ง รองผู้อำนวยการ ครั้งที่ : ' +
            period_sedirectors.period_sedirector_times +
            ' ปี : ' +
            period_sedirectors.period_sedirector_year
        "
      >
        <v-row no-gutters>
          <v-col cols="12" md="12">
            <v-card class="pa-1">
              <v-simple-table>
                <tbody>
                  <tr>
                    <td class="text-center">
                      <h3 class="font-weight-bold" color="info">
                        ผู้เสนอสมัคร :
                        <v-btn
                          @click="sedirector_appQueryAll()"
                          fab
                          color="success"
                          class="font-weight-bold"
                          dark
                          small
                          >{{ sedirector_appCount.sumAll }}
                        </v-btn>
                        คน
                      </h3>
                    </td>
                    <td class="text-center">
                      <h3 class="font-weight-bold" color="info">
                        อยู่ระหว่างทำรายการ :
                        <v-btn
                          @click="Searchtemporary()"
                          fab
                          color="warning"
                          class="font-weight-bold"
                          dark
                          small
                          >{{ sedirector_appCount.sumbt }}
                        </v-btn>
                        คน
                      </h3>
                    </td>

                    <td class="text-center">
                      <h3 class="font-weight-bold" color="info">
                        สอจ.ขอยกเลิก :
                        <v-btn
                          @click="SearchCancel()"
                          fab
                          color="red"
                          class="font-weight-bold"
                          dark
                          small
                          >{{ sedirector_appCount.countCCs }}
                        </v-btn>
                        คน
                      </h3>
                    </td>

                    <td class="text-center">
                      <h3 class="font-weight-bold" color="info">
                        ผ่านคุณสมบัติ :
                        <v-btn
                          @click="SearchPass()"
                          fab
                          color="success"
                          class="font-weight-bold"
                          dark
                          small
                          >{{ sedirector_appCount.pass }}
                        </v-btn>
                        คน
                      </h3>
                    </td>

                    <td class="text-center">
                      <h3 class="font-weight-bold" color="info">
                        ไม่ผ่านคุณสมบัติ :
                        <v-btn
                          @click="SearchFail()"
                          fab
                          color="red"
                          class="font-weight-bold"
                          dark
                          small
                          >{{ sedirector_appCount.fail }}
                        </v-btn>
                        คน
                      </h3>
                    </td>


                  </tr>
                  <tr>
                    <td class="text-center">
                      <v-btn color="warning" rounded @click="Searchtemporary()"
                        >ไม่ได้บันทึกรายการ</v-btn
                      >
                    </td>
                    <td class="text-center">
                      <v-btn
                        rounded
                        color="info"
                        :href="
                          '#/admin/print_sedirector_app/?times=' +
                            period_sedirectors.period_sedirector_times +
                            '&years=' +
                            period_sedirectors.period_sedirector_year
                        "
                        target="_blank"
                        ><v-icon>mdi-printer</v-icon>ส่งออกข้อมูลผู้สมัคร
                      </v-btn>
                    </td>

                    <td class="text-center" colspan="3">
                      <v-btn @click="noticeApproveIpa()" rounded color="success"
                        ><v-icon>mdi-cast-connected</v-icon
                        >ประกาศผลการตรวจสอบคุณสมบัติ
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table></v-card
            >
          </v-col>
        </v-row>

        <v-select
          v-model="selectedHeaders"
          :items="headers"
          label="เลือกคอลัมน์ที่ต้องการแสดง"
          multiple
          outlined
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 6">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 6" class="grey--text caption"
              >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
            >
          </template>
        </v-select>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          dense
          filled
          class="mb-2"
        />

        <v-data-table
          color="success"
          :loading="loading"
          :headers="showHeaders"
          :items="sedirector_apps"
          :search="search"
          item-key="sedirector_app_idref"
          :item-class="row_classes"
        >
          <template v-slot:item.sedirector_app_pvc_approve_ipa="{ item }">
            <div>
              <v-icon
                color="green"
                v-if="item.sedirector_app_pvc_approve_ipa === 'notice'"
                >mdi-cast</v-icon
              >
              <v-icon color="dark" v-else>mdi-cast-off</v-icon>
            </div>
          </template>

          <template v-slot:item.sedirector_app_position="{ item }">
            <v-chip v-if="item.sedirector_app_position === 'teach'">
              ครูผู้สอน
            </v-chip>
            <v-chip v-else-if="item.sedirector_app_position === 'head'">
              หัวหน้าแผนกวิชา
            </v-chip>
            <v-chip v-else-if="item.sedirector_app_position === 'director'">
              รองผู้อำนวยการ
            </v-chip>
          </template>

          <template v-slot:item.sedirector_app_discipline="{ item }">
            <v-chip v-if="item.sedirector_app_discipline === 'ever'">
              เคย
            </v-chip>
            <v-chip v-else-if="item.sedirector_app_discipline === 'never'">
              ไม่เคย
            </v-chip>
          </template>

          <template v-slot:item.sedirector_app_status="{ item }">
            <v-chip color="success" v-if="item.sedirector_app_status === '8'">
              บันทึก
            </v-chip>
            <v-chip
              @click="changeStatus(item.sedirector_app_idref)"
              color="warning"
              v-else
            >
              {{ item.sedirector_app_status }} : ยังไม่ได้บันทึก
            </v-chip>
          </template>


          <template v-slot:item.sedirector_app_time="{ item }">
            {{ item.sedirector_app_time + "/" + item.sedirector_app_year }}
          </template>

          <template v-slot:item.sedirector_app_pvc_approve="{ item }">
            <div>
              <v-chip
                v-if="item.sedirector_app_pvc_approve === 'pass'"
                color="success"
                dark
                >ผ่าน</v-chip
              >
              <v-chip
                v-else-if="item.sedirector_app_pvc_approve === 'fail'"
                color="red"
                dark
                >ไม่ผ่าน</v-chip
              >
              <v-btn
                rounded
                v-else-if="item.sedirector_app_pvc_approve === 'CC'"
                color="red"
                dark
                @click="cancelPVCAppreve(item.sedirector_app_idref)"
              >
                <v-icon>mdi-alert</v-icon> ขอยกเลิก</v-btn
              >
            </div>
          </template>
          <template v-slot:item.sedirector_app_file="{ item }">
            <div v-if="item.sedirector_app_file">
              <div>
                <v-icon color="red" large v-if="item.ccDocumentFile === 'cc'"
                  >mdi-information</v-icon
                >
                <v-btn
                  large
                  text
                  rounded
                  color="green"
                  @click="pdfHrvecRegisDir(item.sedirector_app_file)"
                  ><v-icon large>mdi-printer</v-icon></v-btn
                >
                <v-icon
                  v-if="
                    item.sedirector_app_pvc_approve === 'pass' ||
                      item.sedirector_app_pvc_approve === 'fail' ||
                      item.sedirector_app_pvc_approve === 'CC'
                  "
                  >mdi-checkbox-marked</v-icon
                >
                <v-icon
                  v-else
                  large
                  color="red"
                  @click.stop="CancelFilePDF(item.sedirector_app_idref)"
                  >mdi-delete-circle</v-icon
                >
              </div>
            </div>
            <div v-else>
              <v-chip dark color="warning">
                ไม่ได้แนบไฟล์
              </v-chip>
            </div>
          </template>

          <template v-slot:item.sedirector_app_file_A="{ item }">
            <div v-if="item.sedirector_app_file_A">
              <v-icon
                color="red"
                large
                v-if="item.sedirector_app_file_A_CC === 'cc'"
                >mdi-information</v-icon
              >
              <v-btn
                text
                rounded
                color="green"
                large
                @click="pdfHrvecRegisDir(item.sedirector_app_file_A)"
                ><v-icon large>mdi-printer</v-icon></v-btn
              >
              <v-icon
                v-if="
                  item.sedirector_app_pvc_approve === 'pass' ||
                    item.sedirector_app_pvc_approve === 'fail' ||
                    item.sedirector_app_pvc_approve === 'CC'
                "
                >mdi-checkbox-marked</v-icon
              >
              <v-icon
                v-else
                color="red"
                large
                @click.stop="CancelFilePDF(item.sedirector_app_idref)"
                >mdi-delete-circle</v-icon
              >
            </div>
            <div v-else>
              <v-chip dark color="warning">
                ไม่ได้แนบไฟล์
              </v-chip>
            </div>
          </template>

          <template v-slot:item.sedirector_app_file_B="{ item }">
            <div v-if="item.sedirector_app_file_B">
              <v-icon
                color="red"
                large
                v-if="item.sedirector_app_file_B_CC === 'cc'"
                >mdi-information</v-icon
              >
              <v-btn
                text
                rounded
                color="green"
                large
                @click="pdfHrvecRegisDir(item.sedirector_app_file_B)"
                ><v-icon large>mdi-printer</v-icon></v-btn
              >
              <v-icon
                v-if="
                  item.sedirector_app_pvc_approve === 'pass' ||
                    item.sedirector_app_pvc_approve === 'fail' ||
                    item.sedirector_app_pvc_approve === 'CC'
                "
                >mdi-checkbox-marked</v-icon
              >
              <v-icon
                v-else
                color="red"
                large
                @click.stop="CancelFilePDF(item.sedirector_app_idref)"
                >mdi-delete-circle</v-icon
              >
            </div>
            <div v-else>
              <v-chip dark color="warning">
                ไม่ได้แนบไฟล์
              </v-chip>
            </div>
          </template>

          <template v-slot:item.sedirector_app_datetime="{ item }">
            {{
              item.sedirector_app_datetime
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>

          <template v-slot:item.del="{ item }">
            <v-icon
              color="red"
              large
              @click.stop="deletetpteach(item.sedirector_app_idref)"
              >mdi-delete-circle</v-icon
            >
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model DeleteTpteachDrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="DeleteTpteachDrdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="red"
              icon="mdi-clipboard-text"
              title="ลบรายการผู้สมัครรายนี้"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="DeleteTpteachDrdialogform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ sedirector_apps_id_ref.sedirector_app_idref }}
                        {{ sedirector_apps_id_ref.sedirector_app_idref }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ sedirector_apps_id_ref.sedirector_app_idcard }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ sedirector_apps_id_ref.title_s
                        }}{{ sedirector_apps_id_ref.frist_name }}
                        {{ sedirector_apps_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ sedirector_apps_id_ref.college_code }}
                        {{ sedirector_apps_id_ref.user_firstname }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ sedirector_apps_id_ref.id_position }}
                      </h3>
                      <h4>
                        สมัครครั้งที่ :
                        {{ sedirector_apps_id_ref.sedirector_app_time }} ปี :
                        {{ this.sedirector_apps_id_ref.year_ss }}
                      </h4>
                      <h4>
                        คำนวณอายุงาน ณ สถานศึกษาปัจจุบัน โดยใช้วันที่ :
                        {{
                          period_sedirectors.period_sedirector_cal_end
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h4>
                    </v-col>
                    <v-col>
                      <v-text-field
                        filled
                        rounded
                        type="password"
                        label="Password"
                        :rules="[v => v === '0612698358']"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="DeleteTpteachDrdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn large color="green" @click.stop="DeleteSubmit()" rounded>
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model CancelFilePDFDrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="CancelFilePDFDrdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="ยกเลิกการแนบไฟล์"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="CancelFilePDFDrdialogform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ sedirector_apps_id_ref.sedirector_app_idref }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ sedirector_apps_id_ref.sedirector_app_idcard }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ sedirector_apps_id_ref.title_s
                        }}{{ sedirector_apps_id_ref.frist_name }}
                        {{ sedirector_apps_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ sedirector_apps_id_ref.college_code }}
                        {{ sedirector_apps_id_ref.user_firstname }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ sedirector_apps_id_ref.id_position }}
                      </h3>
                    </v-col>
                    <v-col>
                      <v-text-field
                        filled
                        rounded
                        type="password"
                        label="Password"
                        :rules="[v => v === '0612698358']"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="CancelFilePDFDrdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="CandelFilePDFSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยันยกเลิก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model CancelFilePDFProvinceDrdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="CancelFilePDFProvinceDrdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="ยกเลิกการแนบไฟล์ สำนักงานอาชีวศึกษาจังหวัด"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="CancelFilePDFProvinceDrdialogform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ sedirector_apps_id_ref.sedirector_app_idref }}
                        {{ sedirector_apps_id_ref.sedirector_app_idref }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ sedirector_apps_id_ref.sedirector_app_idcard }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ sedirector_apps_id_ref.title_s
                        }}{{ sedirector_apps_id_ref.frist_name }}
                        {{ sedirector_apps_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ sedirector_apps_id_ref.college_code }}
                        {{ sedirector_apps_id_ref.user_firstname }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ sedirector_apps_id_ref.id_position }}
                      </h3>
                      <h4>
                        สมัครครั้งที่ :
                        {{ sedirector_apps_id_ref.sedirector_app_time }} ปี :
                        {{ this.sedirector_apps_id_ref.year_ss }}
                      </h4>
                      <h4>
                        คำนวณอายุงาน ณ สถานศึกษาปัจจุบัน โดยใช้วันที่ :
                        {{
                          period_sedirectors.period_sedirector_cal_end
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h4>
                    </v-col>
                    <v-col>
                      <v-text-field
                        filled
                        rounded
                        type="password"
                        label="Password"
                        :rules="[v => v === '0612698358']"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="CancelFilePDFProvinceDrdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="CancelFilePDFProvinceDrdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยันยกเลิก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdfHrvecRegisDirdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdfHrvecRegisDirdialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HrvecRegisDir/' + pdffiles"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>{{ snackbar.text }}</v-card-text>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import sedirector_app_bar from "../../components/admin/sedirector_app_bar.vue";

export default {
  data() {
    return {
      value: "2",
      loading: false,
      ApiKey: "HRvec2021",
      position: "ครู",
      branch_s: [],
      sedirector_app_idref: [],
      valid: true,
      clear_dataDialog: false,
      DeleteTpteachDrdialog: false,
      CancelFilePDFDrdialog: false,
      CancelFilePDFProvinceDrdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },

      sedirector_apps: [],
      search: "",
      pagination: {},
      singleSelect: false,
      selected: [],
      headers: [],
      selectedHeaders: [],
      headersMap: [
        {
          text: "Action",
          align: "center",
          value: "sedirector_app_pvc_approve_ipa"
        },
        { text: "สถานะ", align: "center", value: "sedirector_app_status" },
        {
          text: "สอจ.",
          align: "center",
          value: "sedirector_app_pvc_approve"
        },
        { text: "จังหวัด", align: "center", value: "province_name" },
        {
          text: "รหัสบัตรประชาชน",
          align: "center",
          value: "sedirector_app_idcard"
        },
       
        { text: "ชื่อ-นามสกุล", align: "left", value: "fristnames" },
        { text: "สังกัดปัจจุบัน", align: "center", value: "user_firstname" },
        { text: "เลขที่ตำแหน่งเดิม", align: "left", value: "id_position" },
        {
          text: "อายุงาน ณ ปัจจุบัน",
          align: "center",
          value: "sedirector_app_agework"
        },
        { text: "ตำแหน่ง", align: "center", value: "sedirector_app_position" },
        { text: "อายุงาน", align: "center", value: "sedirector_age_position" },
        {
          text: "อายุงานในตำแหน่ง",
          align: "center",
          value: "sedirector_age_position_work5"
        },
        { text: "วินัย", align: "center", value: "sedirector_app_discipline" },
        { text: "ใบสมัคร", align: "center", value: "sedirector_app_file" },
        {
          text: "เอกสารภาค ก.",
          align: "center",
          value: "sedirector_app_file_A"
        },
        {
          text: "เอกสารภาค ข.",
          align: "center",
          value: "sedirector_app_file_B"
        },

    

        {
          text: "วันที่ทำรายการ",
          align: "center",
          value: "sedirector_app_datetime"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      sedirector_apps_id_ref: [],
      period_sedirectors: [],
      updatecomment: {},
      transference_locationTfl: [],
      updatetlMcommittee: {},
      sedirector_appCount: {},
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB",
        value => !!value || ""
      ],
      pdfHrvecRegisDirdialog: false,
      pdffiles: "",
      update_sedirector_app: {}
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },

  async mounted() {
    await this.period_sedirectorQuery();
    await this.sedirector_appQueryAll();
    await this.Secsioncheck();
  },
  methods: {
    async Secsioncheck() {
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      
      if (userSession.userauthorityAssessSedirector != "1") {
        Swal.fire({
          icon: "error",
          title: "ไม่ได้รับสิทธิ์เข้าถึงระบบ",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/admin");
          }
        });
      }
    },
    async noticeApproveIpa() {
      Swal.fire({
        title: "ประกาศรายชื่อผู้ผ่านคุณสมบัติ ?",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ประกาศ",
        cancelButtonText: "ยกเลิก",
        denyButtonText: `ยกเลิกประกาศ`
      }).then(async result => {
        if (result.isConfirmed) {
          this.update_sedirector_app.ApiKey = this.ApiKey;
          this.update_sedirector_app.sedirector_app_year = this.period_sedirectors.period_sedirector_year;
          this.update_sedirector_app.sedirector_app_time = this.period_sedirectors.period_sedirector_times;
          this.update_sedirector_app.sedirector_app_pvc_approve_ipa = "notice";
          let resultupdate = await this.$http.post(
            "sedirector_app.update.admin.php",
            this.update_sedirector_app
          );
          
          if (resultupdate.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.sedirector_appQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        } else if (result.isDenied) {
          this.update_sedirector_app.ApiKey = this.ApiKey;
          this.update_sedirector_app.sedirector_app_year = this.period_sedirectors.period_sedirector_year;
          this.update_sedirector_app.sedirector_app_time = this.period_sedirectors.period_sedirector_times;
          this.update_sedirector_app.sedirector_app_pvc_approve_ipa = "";
          let resultupdate = await this.$http.post(
            "sedirector_app.update.admin.php",
            this.update_sedirector_app
          );
          
          if (resultupdate.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.sedirector_appQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async pdfHrvecRegisDir(pdffile) {
      this.pdffiles = "";
      this.pdffiles = pdffile;
      
      this.pdfHrvecRegisDirdialog = true;
    },

    async changeStatus(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;
      let text =
        this.sedirector_apps_id_ref.frist_name +
        this.sedirector_apps_id_ref.last_name;

      Swal.fire({
        title: "คุณต้องการเปลี่ยนสถานะ?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "เปลี่ยนสถานะ",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
          this.sedirector_apps_id_ref.sedirector_app_status = "save";
          let resultupdate = await this.$http.post(
            "sedirector_app.update.php",
            this.sedirector_apps_id_ref
          );
          await this.sedirector_appQueryAll();
        }
      });
    },

    async cancelPVCAppreve(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;
      let text = this.sedirector_apps_id_ref.fristnames;
      
      Swal.fire({
        title: "คุณต้องการเปลี่ยนสถานะ?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "เปลี่ยนสถานะ",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
          this.sedirector_apps_id_ref.sedirector_app_pvc_approve = "";
          let resultupdate = await this.$http.post(
            "sedirector_app.update.php",
            this.sedirector_apps_id_ref
          );
          await this.sedirector_appQueryAll();
        }
      });
    },
    async Searchtemporary() {
      this.loading=true
      let result = await this.$http
        .post("sedirector_app.php", {
          ApiKey: this.ApiKey,
          sedirector_app_time: this.period_sedirectors.period_sedirector_times,
          sedirector_app_year: this.period_sedirectors.period_sedirector_year
        })
        .finally(() => (this.loading = false));
      let data = result.data;
      let obj = {};
      let arr = [];
      data.forEach(value => {
        if (value.sedirector_app_status < 8) {
          obj["province_name"] = value.province_name;
          obj["sedirector_app_idcard"] = value.sedirector_app_idcard;
          obj["sedirector_app_status"] = value.sedirector_app_status;
          obj["fristnames"] = value.fristnames;
          obj["user_firstname"] = value.user_firstname;
          obj["id_position"] = value.id_position;
          obj["sedirector_app_time"] = value.sedirector_app_time;
          obj["sedirector_app_agework"] = value.sedirector_app_agework;
          obj["sedirector_app_position"] = value.sedirector_app_position;
          obj["sedirector_age_position"] = value.sedirector_age_position;
          obj["sedirector_age_position_work5"] =
            value.sedirector_age_position_work5;
          obj["sedirector_app_discipline"] = value.sedirector_app_discipline;
          arr.push({
            province_name: obj["province_name"],
            sedirector_app_idcard: obj["sedirector_app_idcard"],
            sedirector_app_status: obj["sedirector_app_status"],
            fristnames: obj["fristnames"],
            user_firstname: obj["user_firstname"],
            id_position: obj["id_position"],
            sedirector_app_time: obj["sedirector_app_time"],
            sedirector_app_agework: obj["sedirector_app_agework"],
            sedirector_app_position: obj["sedirector_app_position"],
            sedirector_age_position: obj["sedirector_age_position"],
            sedirector_age_position_work5: obj["sedirector_age_position_work5"],
            sedirector_app_discipline: obj["sedirector_app_discipline"]
          });
        }
      });
      this.sedirector_apps = arr;
    },

    async SearchCancel() {
      let result = await this.$http
        .post("sedirector_app.php", {
          ApiKey: this.ApiKey,
          sedirector_app_time: this.period_sedirectors.period_sedirector_times,
          sedirector_app_year: this.period_sedirectors.period_sedirector_year
        })
        .finally(() => (this.loading = false));
      let data = result.data;
      let obj = {};
      let arr = [];
      data.forEach(value => {
        if (value.sedirector_app_pvc_approve == "CC") {
          obj["province_name"] = value.province_name;
          obj["sedirector_app_idcard"] = value.sedirector_app_idcard;
          obj["sedirector_app_status"] = value.sedirector_app_status;
          obj["fristnames"] = value.fristnames;
          obj["user_firstname"] = value.user_firstname;
          obj["id_position"] = value.id_position;
          obj["sedirector_app_time"] = value.sedirector_app_time;
          obj["sedirector_app_agework"] = value.sedirector_app_agework;
          obj["sedirector_app_position"] = value.sedirector_app_position;
          obj["sedirector_age_position"] = value.sedirector_age_position;
          obj["sedirector_age_position_work5"] =
            value.sedirector_age_position_work5;
          obj["sedirector_app_discipline"] = value.sedirector_app_discipline;
          obj["sedirector_app_file"] = value.sedirector_app_file;
          obj["sedirector_app_file_A"] = value.sedirector_app_file_A;
          obj["sedirector_app_file_B"] = value.sedirector_app_file_B;
          obj["sedirector_app_pvc_approve"] = value.sedirector_app_pvc_approve;
          obj["sedirector_app_datetime"] = value.sedirector_app_datetime;

          arr.push({
            province_name: obj["province_name"],
            sedirector_app_idcard: obj["sedirector_app_idcard"],
            sedirector_app_status: obj["sedirector_app_status"],
            fristnames: obj["fristnames"],
            user_firstname: obj["user_firstname"],
            id_position: obj["id_position"],
            sedirector_app_time: obj["sedirector_app_time"],
            sedirector_app_agework: obj["sedirector_app_agework"],
            sedirector_app_position: obj["sedirector_app_position"],
            sedirector_age_position: obj["sedirector_age_position"],
            sedirector_age_position_work5: obj["sedirector_age_position_work5"],
            sedirector_app_discipline: obj["sedirector_app_discipline"],

            sedirector_app_file: obj["sedirector_app_file"],
            sedirector_app_file_A: obj["sedirector_app_file_A"],
            sedirector_app_file_B: obj["sedirector_app_file_B"],
            sedirector_app_pvc_approve: obj["sedirector_app_pvc_approve"],
            sedirector_app_datetime: obj["sedirector_app_datetime"]
          });
        }
      });
      this.sedirector_apps = arr;
    },

    async SearchFail() {
      let result = await this.$http
        .post("sedirector_app.php", {
          ApiKey: this.ApiKey,
          sedirector_app_time: this.period_sedirectors.period_sedirector_times,
          sedirector_app_year: this.period_sedirectors.period_sedirector_year
        })
        .finally(() => (this.loading = false));
      let data = result.data;
      let obj = {};
      let arr = [];
      data.forEach(value => {
        if (value.sedirector_app_pvc_approve == "fail") {
          obj["province_name"] = value.province_name;
          obj["sedirector_app_idcard"] = value.sedirector_app_idcard;
          obj["sedirector_app_status"] = value.sedirector_app_status;
          obj["fristnames"] = value.fristnames;
          obj["user_firstname"] = value.user_firstname;
          obj["id_position"] = value.id_position;
          obj["sedirector_app_time"] = value.sedirector_app_time;
          obj["sedirector_app_agework"] = value.sedirector_app_agework;
          obj["sedirector_app_position"] = value.sedirector_app_position;
          obj["sedirector_age_position"] = value.sedirector_age_position;
          obj["sedirector_age_position_work5"] =
            value.sedirector_age_position_work5;
          obj["sedirector_app_discipline"] = value.sedirector_app_discipline;
          obj["sedirector_app_file"] = value.sedirector_app_file;
          obj["sedirector_app_file_A"] = value.sedirector_app_file_A;
          obj["sedirector_app_file_B"] = value.sedirector_app_file_B;
          obj["sedirector_app_pvc_approve"] = value.sedirector_app_pvc_approve;
          obj["sedirector_app_datetime"] = value.sedirector_app_datetime;

          arr.push({
            province_name: obj["province_name"],
            sedirector_app_idcard: obj["sedirector_app_idcard"],
            sedirector_app_status: obj["sedirector_app_status"],
            fristnames: obj["fristnames"],
            user_firstname: obj["user_firstname"],
            id_position: obj["id_position"],
            sedirector_app_time: obj["sedirector_app_time"],
            sedirector_app_agework: obj["sedirector_app_agework"],
            sedirector_app_position: obj["sedirector_app_position"],
            sedirector_age_position: obj["sedirector_age_position"],
            sedirector_age_position_work5: obj["sedirector_age_position_work5"],
            sedirector_app_discipline: obj["sedirector_app_discipline"],

            sedirector_app_file: obj["sedirector_app_file"],
            sedirector_app_file_A: obj["sedirector_app_file_A"],
            sedirector_app_file_B: obj["sedirector_app_file_B"],
            sedirector_app_pvc_approve: obj["sedirector_app_pvc_approve"],
            sedirector_app_datetime: obj["sedirector_app_datetime"]
          });
        }
      });
      this.sedirector_apps = arr;
    },


    async SearchPass() {
      let result = await this.$http
        .post("sedirector_app.php", {
          ApiKey: this.ApiKey,
          sedirector_app_time: this.period_sedirectors.period_sedirector_times,
          sedirector_app_year: this.period_sedirectors.period_sedirector_year
        })
        .finally(() => (this.loading = false));
      let data = result.data;
      let obj = {};
      let arr = [];
      data.forEach(value => {
        if (value.sedirector_app_pvc_approve == "pass") {
          obj["province_name"] = value.province_name;
          obj["sedirector_app_idcard"] = value.sedirector_app_idcard;
          obj["sedirector_app_status"] = value.sedirector_app_status;
          obj["fristnames"] = value.fristnames;
          obj["user_firstname"] = value.user_firstname;
          obj["id_position"] = value.id_position;
          obj["sedirector_app_time"] = value.sedirector_app_time;
          obj["sedirector_app_agework"] = value.sedirector_app_agework;
          obj["sedirector_app_position"] = value.sedirector_app_position;
          obj["sedirector_age_position"] = value.sedirector_age_position;
          obj["sedirector_age_position_work5"] =
            value.sedirector_age_position_work5;
          obj["sedirector_app_discipline"] = value.sedirector_app_discipline;
          obj["sedirector_app_file"] = value.sedirector_app_file;
          obj["sedirector_app_file_A"] = value.sedirector_app_file_A;
          obj["sedirector_app_file_B"] = value.sedirector_app_file_B;
          obj["sedirector_app_pvc_approve"] = value.sedirector_app_pvc_approve;
          obj["sedirector_app_datetime"] = value.sedirector_app_datetime;

          arr.push({
            province_name: obj["province_name"],
            sedirector_app_idcard: obj["sedirector_app_idcard"],
            sedirector_app_status: obj["sedirector_app_status"],
            fristnames: obj["fristnames"],
            user_firstname: obj["user_firstname"],
            id_position: obj["id_position"],
            sedirector_app_time: obj["sedirector_app_time"],
            sedirector_app_agework: obj["sedirector_app_agework"],
            sedirector_app_position: obj["sedirector_app_position"],
            sedirector_age_position: obj["sedirector_age_position"],
            sedirector_age_position_work5: obj["sedirector_age_position_work5"],
            sedirector_app_discipline: obj["sedirector_app_discipline"],

            sedirector_app_file: obj["sedirector_app_file"],
            sedirector_app_file_A: obj["sedirector_app_file_A"],
            sedirector_app_file_B: obj["sedirector_app_file_B"],
            sedirector_app_pvc_approve: obj["sedirector_app_pvc_approve"],
            sedirector_app_datetime: obj["sedirector_app_datetime"]
          });
        }
      });
      this.sedirector_apps = arr;
    },




    row_classes(item) {
      if (item.sedirector_app_pvc_approve == "CC") {
        return "yellow";
      }
    },

    async CancelFilePDF(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;
      this.CancelFilePDFDrdialog = true;
    },

    async CandelFilePDFSubmit() {
      if (this.$refs.CancelFilePDFDrdialogform.validate()) {
        let result;
        this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
        this.sedirector_apps_id_ref.sedirector_app_status = 7;

        result = await this.$http.post(
          "sedirector_app.update.php",
          this.sedirector_apps_id_ref
        );

        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.sedirector_appQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.CancelFilePDFDrdialog = false;
      }
    },
    async deletetpteach(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        id_ref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;
      this.DeleteTpteachDrdialog = true;
    },
    async DeleteSubmit() {
      if (this.$refs.DeleteTpteachDrdialogform.validate()) {
        this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "sedirector_app.delete.php",
          this.sedirector_apps_id_ref
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.sedirector_appQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.DeleteTpteachDrdialog = false;
      }
    },
    async period_sedirectorQuery() {
      let result_period_sedirector;
      result_period_sedirector = await this.$http.post(
        "period_sedirector.php",
        {
          ApiKey: this.ApiKey,
          period_sedirector_process: "1",
          period_sedirector_id: "201"
        }
      );
      this.period_sedirectors = result_period_sedirector.data;
    },

    async sedirector_appQueryAll() {
      this.loading=true

      let result = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_time: this.period_sedirectors.period_sedirector_times,
        sedirector_app_year: this.period_sedirectors.period_sedirector_year
      }).finally(() => (this.loading = false));
      this.sedirector_apps = result.data;
      let data = this.sedirector_apps;
      let sum = 0;
      let sumbt = 0;
      let sumdoc = 0;
      let sedirector_app_file_A = 0;
      let sedirector_app_file_B = 0;
      let sumdocpvcfile = 0;
      let countCCs = 0;
      let pass = 0;
      let fail=0;
   
      data.forEach(value => {
        if (value.sedirector_app_status >= 8) {
          sum = sum + 1;
        } else {
          sumbt += 1;
        }

        if (value.sedirector_app_file) {
          sumdoc +=1;
        }
        if (value.sedirector_app_file_A) {
          sedirector_app_file_A += 1;
        }
        if (value.sedirector_app_file_B) {
          sedirector_app_file_B+= 1;
        }

        if (value.tpvecprovince_scoreFile) {
          sumdocpvcfile += 1;
        }

        if (value.sedirector_app_pvc_approve == "CC") {
          countCCs+= 1;
        }

        if (value.sedirector_app_pvc_approve == "pass") {
          pass += 1;
        }

        if (value.sedirector_app_pvc_approve == "fail") {
          fail += 1;
        }


      });
      this.sedirector_appCount.sumAll = sum;
      this.sedirector_appCount.sumbt = sumbt;
      this.sedirector_appCount.sumdoc = sumdoc;
      this.sedirector_appCount.sedirector_app_file_A = sedirector_app_file_A;
      this.sedirector_appCount.sedirector_app_file_B = sedirector_app_file_B;
      this.sedirector_appCount.sumdocpvcfile = sumdocpvcfile;
      this.sedirector_appCount.countCCs = countCCs;
      this.sedirector_appCount.pass = pass;
      this.sedirector_appCount.fail = fail;
    },

    getColor(calories) {
      if (calories > 0) return "green";
      else return "";
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    time_stamp() {
      let time = Date.now();
      return time;
    },
    period_sedirector_years() {
      let yyyy = this.period_sedirectors.period_sedirector_year;
      return yyyy;
    },
    color() {
      return "lime darken-4";
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  },
  components: { sedirector_app_bar }
};
</script>

<style scoped>
.orange {
  background-color: rgb(236, 194, 115);
}
</style>
